export default {
  title: 'SPITCH Portal',
  agreementLink: '/shared-pdf/spitch-policy.pdf',

  //https://www.i18next.com/translation-function/formatting
  money: {
    locale: 'en-US',
    currency: 'CHF',
    currencySign: 'CHF',
  },

  intlLocale: 'en-US', //de-DE,en-US

  socialNetworks: {
    twitter: 'https://twitter.com/Spitch_AG/',
    facebook: 'https://www.facebook.com/spitch.ch',
    telegram: '',
    youtube: 'https://www.youtube.com/channel/UC9sRY9fg_qFWo_UEQNOA1Cw',
  },

  phoneInputMask: '+## ## ### ## ##',
  phoneDisplayMask: '+## ## ### ## ##',

  langs: {
    ru: 'Russian',
    gb: 'English',
    de: 'German',
  },


  materialGroupRestriction: {
    users: 'Choosen users',
    everyone: 'Everyone',
    roles: 'Choosen roles',
  },

  page: {
    cities: {
      newName: 'Please, enter new name',
      headline: 'Add',
      delete: 'Delete',
      update: 'Update',
    },
    docs: {
      addProduct: 'Add product',
      addDoc: 'Add release',
      addRelease: 'Add product',
      createdAt: 'Date',
      open: 'Open',
      delete: 'Delete',
      deleteCheck: 'Are you sure?',
      edit: 'Edit',
      releaseName: 'Product name',
      noRowsFounds: 'No rows',
      all: 'All products',
      lang: 'Language',
      editProduct: 'Edit product',
    },
    loginAgreement: {
      forContinueYouShouldAccept:
        'To continue using the service, you should accept',
      acceptAgreement: 'privacy policy',
    },

    education: {
      info: 'Dear partners, to obtain the status and certificate of completion of training, you need to pass the test',
      result: 'We will inform you about the results of the training by e-mail.',
      question: 'Ask a question',
      note: 'We recommend reviewing the material on BSS products before testing',
      button: {
        engineers: 'Test for engineers',
        managers: 'Test for sales managers',
        materials: 'Product Materials',
      }
    },

    errorHandler: {
      insufficientPermissions: 'No permission',
      notFound: 'Page not found',
      serverError: 'Server error',
      headToIndex: 'Go to Main page',
      errorText: 'Error',
    },

    partnerEdit: {
      headline: 'Edit',
      city: 'City',
      mobilePhone: 'Mobile phone',
      workPhone: 'Business phone',
      isActive: 'Active',
      manager: 'Spitch manager',

      save: 'Save profile',
    },

    login: {
      headlinePersonal: 'Portal user profile',
      forgotPassword: 'Forgot password',
    },

    projectEdit: {
      choose: 'Choose',
      infoHeadline: 'Project information',
      statusChangeReasonField: 'Status change reason',
      department: 'Department',

      statusField: 'Status',
      customerField: 'Customer',
      subjectField: 'Project subject',
      requestedAmountField: 'Projected amount',
      actualAmountField: 'Actual amount',
      amountField: 'Amount',
      dealAtField: 'Expected Deal Date',
      contractField: 'Contact person',
      dateCreatedField: 'Created on',
      documentationDropZone: 'Tender documentation',

      saveProject: 'Save project',
      addProject: 'Add project',
    },

    registration: {
      notFilled: 'Not filled in:',
      acceptAgreement: 'I agree with the privacy policy',
      manager: 'Spitch manager',
      partnerName: 'Partner name',
      headline: 'Partner registration',

      workPhone: 'Business phone',
      mobilePhone: 'Mobile phone',
      partnerSuccessfullyCreated: 'Partner created',

      fieldName: 'Partner name',
      fieldCity: 'City',
      fieldTaxId: 'Tax ID',
      fieldFullName: 'Full name',
      fieldPosition: 'Position',
      fieldEmail: 'Email',
      fieldPersonalPhone: 'Mobile phone',
      fieldWorkPhone: 'Business phone',
      fieldPolicy: 'Privacy policy',
      fieldCaptcha: 'Captcha',
      fieldCountries: 'Countries',
    },
    news: {
      insertLinkPrompt: 'Insert image link:',

      haveNoEvents: 'No events yet',

      subscribeNewsletter: 'Subscribe',
      followedSuccessful: 'Subscribed',
      readNewsHeadline: 'Read the latest Spitch news',

      otherNews: 'Other news',

      event: 'Event',
      showAll: 'Show all',

      newsNotFound: 'News not found',
      newsDoesntExists: 'No news yet',

      addNews: 'Add news',
      allNews: 'All',
      onlyNews: 'News',
      onlyEvents: 'Events',

      editingMode: 'Edit {{what}}',
      addingMode: 'Add {{what}}',

      modeNews: 'news',
      modeEvent: 'events',
    },

    newsAdd: {
      saveAndClose: 'Save and close',
      unpublish: 'Unpublish',

      event: 'event',
      news: 'news',
      toPublish: 'Publish {{what}}',

      multipleNews: 'News',
      multipleEvents: 'Events',

      publishDate: 'Publication date',
      fieldText: 'Text',
      fieldPicture: 'Picture',
      fieldLid: 'Lead',
      fieldTitle: 'Title',
      fieldDateRange: 'Period', //periods :)
    },

    restorePassword: {
      restoreButton: 'Restore',
      rememberedPasswordBack: 'Remembered the password',
      mailWasSent: 'Email sent',
      restorePasswordHeadline: 'Restore password',
    },

    material: {
      addFile: 'Add file',
      editCategories: 'Edit categories',
      editGroups: 'Edit groups',

      type: {
        sales: 'Sales',
        tech: 'Technical',
        ads: 'Agreement templates',
        partnership: 'Partnership',
        cases: 'Cases',
      },

      table: {
        name: 'Name',
        size: 'Size',
        createdAt: 'Created At',
        author: 'Author',
        isActual: 'Is Actual',
      }
    },

    projects: {
      tableCity: 'Country',
      nothingFound: 'Nothing found',
      noRequestsFound: 'No request found',
      resetFilter: 'Reset filters',
      makeSureInputIsCorrect:
        'Make sure the information you entered is correct',
      queryTermNotFound: '{{query}} not found',
      total: 'Total {{count}} contracts for the amount of ',
      tableStatus: 'Status',
      tablePartner: 'Partner',
      tableCustomer: 'Customer',
      tableCreatedAt: 'Created on',
      tableSubject: 'Project subject',
      tableDealAt: 'Expected Deal Date',
      tableAmount: 'Projected Amount',
      newProject: 'New project',
      expireAt: 'Expire at',
      actualAmount: 'Actual amount',
    },

    partners: {
      navActive: 'Active',
      navArchive: 'Archived',
      navRequest: 'Requests',

      addPartner: 'Add partner',
      addPartnerMobile: '+ Partner',

      noPartnersInArchive: 'No archived partners',
      noPartnersAtAll: 'No partners yet',

      sendPartnerToArchive: 'Archive partner',
      acceptTheRequest: 'Approve partner request',

      restorePartner: 'Restore',
      partnerContract: 'Partner contacts',

      partnerSentToArchive: 'Partner archived',
      partnerRestored: 'Partner restored to active',
    },

    partnerProfile: {
      documents: 'DOCUMENTS',
      revenueServiceNumber: 'Tax ID',
      registrationDate: 'Registration',
      companyContractInfo: 'COMPANY CONTACTS',
      managerHeadline: 'SPITCH MANAGER',

      inAmount: 'in the amount of',
      contractsMention: 'contracts',
      noCountriesFollowed: 'No countries allowed',
      noProjects: 'No projects found',
    },

    feedback: {
      title: 'Feedback form',
      label: 'Feedback, questions, wishes',
      button: {
        send: 'Send',
      },
      error: "Send error",
      success: "Sent success",
    }
  },

  component: {
    projectHistory: {
      headline: 'History',
      system: 'System',
      noHistory: 'No records found',
    },

    statusFollow: {
      headline: 'In statuses',
      inCountries: 'In countries',
    },

    countryFollow: {
      headline: 'In countries',
    },

    projectFollow: {
      headline: 'Follow the projects',
      subjectsHeadline: 'Notifications subscribe',
      noSubscribes: 'No subscriptions',
      partnerRegister: 'Partner register',
    },

    managerContractCreation: {
      headline: 'Managers',
    },

    managerUserEmployeeCreation: {
      headline: 'Employees',
    },
    addDocumentDrawer: {
      createHeadline: 'Add release',
      editHeadline: 'Edit release',
      title: 'Title',
      url: 'Link',
      lang: 'Language',
      description: 'Description',
      release: 'Product',
      pdfUrl: 'Pdf link',
    },

    addProductDrawer: {
      sort: 'Sort',
      createHeadline: 'Add product',
      title: 'Title',
      edit: 'Save',
      editHeadline: 'Edit product',
    },

    addCityDrawer: {
      createHeadline: 'Add country',
      title: 'Name',
    },

    userProfileCard: {
      manager: 'Spitch manager',
    },

    managerModal: {
      headline: 'Add manager',
    },

    managerUserEmployeeModal: {
      headline: 'Add employee',
    },

    newsCard: {
      hide: 'Hide news',
    },

    projectsSearchbar: {
      filtersLable: 'Filters',
      clear: 'Clear all',
      status: 'Status',
    },

    employeeSearchbar: {
      addEmployee: 'Add employee',
      clear: 'Clear all',
      filtersLable: 'Filters',

      status: 'Status',
    },

    projectCard: {
      department: 'Department',
      statusDescription: 'Status description',
      requestedAmount: 'Projected amount',
      actualAmount: 'Actual amount',
      contactPerson: 'Contact person',
      partner: 'Partner',
      dealAt: 'Expected Deal Date',
      createdAt: 'Created on',
    },

    partnerStatsMobile: {
      atAll: 'total',
      inWork: 'in progress',
      inMonth: 'per month',
      contractsTotal: 'amount of contracts',

      pluralPartner: 'partners',
      pluralContract: 'contracts',
      partners: 'partners',
    },

    projectStats: {
      inWork: 'in progress',
      inMonth: 'per month',
      atAll: 'total',

      contractsTotal: 'amount of contracts',
      pluralPartner: 'partners',
      pluralContract: 'contracts',
    },

    dropZone: {
      dropFileHere: 'Drag the file to this area or',
      orChooseManual: 'upload from computer',
      fileRequirement: 'The maximum size is 20 MB.',
      wrongSizeError: '{{name}} has invalid size of {{size}} MB',

      uploadButton: 'Upload',
    },

    addFileDrawer: {
      createHeadline: 'Add material',
      section: 'Section',
      category: 'Category',
      group: 'Group',
      url: 'Link',

      typeFile: 'File',
      typeUrl: 'Link',

      name: 'Name',
    },

    editFileDrawer: {
      editHeadline: 'Edit file',
      section: 'Section',
      category: 'Category',
      group: 'Group',
      publicId: 'Public Id',

      url: 'Link',

      typeFile: 'File',
      typeUrl: 'Link',

      publicIdHintEmpty: 'Material will have no public link',
      publicIdHintFilled: 'Material will have public link',

      actual: 'Actual',
    },

    categoryCreateDrawer: {
      categoryName: 'Name',
      headline: 'Add category',
      editCategories: 'Edit categories',
    },

    materialGroupDrawer: {
      editCategory: 'Edit category',
      editGroup: 'Edit group',
      editGroups: 'Edit groups',
      createGroup: 'Create group',

      category: 'Category',
      section: 'Section',
      name: 'Name',

      canAdd: 'Adding',
      canView: 'View',

      canAddAccessType: 'Adding access type',
      canViewAccessType: 'Viewing access type',
    },

    changePassword: {
      headline: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatNewPassword: 'Repeat new password',
      changePassword: 'Change password',

      passwordChanged: 'Password changed',
      passwordCantBeChanged: 'The field cannot be empty',
      passwordsNotEqual: 'Passwords do not match',
    },
  },

  button: {
    delete: 'Delete',
    add: 'Add',
    login: 'Login',
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    editProfile: 'Edit profile',
    editProject: 'Edit project',
    register: 'Register',
    restore: 'Restore',
    continue: 'Continue',
    externalLink: 'EXTERNAL LINK',
  },

  words: {
    noEmployees: 'No employees found',
    allCities: 'All countries',
    city: 'Country',
    taxId: 'Tax ID',
    fullNameAbbr: 'Full name',
    email: 'Email',
    password: 'Password',
    phone: 'Phone',
    partner: 'Partner',
    position: 'Position',
    firstAndLastNames: 'First and Last Names',
    search: 'Search',
    allPartners: 'All Partners',
    projects: 'Projects',
    title: 'Title',
    reset: 'Reset',
    countries: 'Countries',
    accept: 'Accept',
    role: 'Role',
    createdAt: 'Created at',
  },

  role: {
    all: 'All roles',
    partner: 'Partner',
    employee: 'Employee',
    admin: 'Administrator',
    customer: 'Customer',
    partner_admin: 'Partner admin',
    documentation_admin: 'Documentation team',
    end_customer: 'End customer',
    internal_team: 'Internal team',
  },

  header: {
    docs: 'Documentation',
    project: 'Project',
    projects: 'Projects',
    partners: 'Partners',
    materials: 'Materials',
    companyProfile: 'Company Profile',
    news: 'News',
    employees: 'Employees',
    products: 'Products',
    countries: 'Countries',
    education: 'Education',

    productsLinks: [
      {
        title: 'Virtual Assistants (VA)',
        link: 'https://spitch.ai/virtual_assistants/',
      },
      {
        title: 'Speech Analytics (SA)',
        link: 'https://spitch.ai/speech_analytics/',
      },

      {
        title: 'Voice Biometrics (VB)',
        link: 'https://spitch.ai/voice_biometrics/',
      },

      {
        title: 'Knowledge Base (KB)',
        link: 'https://spitch.ai/knowledge_base/',
      },

      {
        title: 'Chat Platform (CP)',
        link: 'https://spitch.ai/chat_platform/',
      },

      {
        title: 'Agent Assiatant Suite (AA)',
        link: 'https://spitch.ai/agent_assistant_suite/',
      },
    ],

    button: {
      createProject: 'Create project',
      exit: 'Exit',
      feedback: 'Feedback form',
    },
  },

  projectStatus: {
    all: 'all statuses',
    active: 'active',
    closed: 'rejected',
    expired: 'closed lost',
    inProcess: 'approval',
    pending: 'closed won',
  },

  toastNotifications: {
    featureDate: 'Date have to be today or later',
    register:
      'Account successfully created. You will receive a password after admin approval',
    applied: 'Saved!',
    cityCreated: 'Country created',
    cityUpdated: 'Country updated',
    docCreated: 'Document created',
    docEdited: 'Document edited',
    weRBuildingTheSheet: 'Do not close the page, we are building the document',
    tableRReady: 'Table ready',

    newsUpdated: 'News updated',
    newsCreated: 'News created',
    partnerChanged: 'Partner changed',

    projectUpdated: 'Project updated',
    projectCreated: 'Project created',

    materialCreated: 'Material created',

    employeeUpdated: 'Employee updated',
    employeeCreated: 'Employee created',
  },

  validation: {
    required: 'Required field',
    captchaRequired: 'Need to pass captcha',
    email: 'Check your email address',
    emailTaken: 'Email taken',
    wrongEmail: 'Wrong email',

    authWrongCredentials: 'Wrong email or password',
    authUpdateRejected: 'Update error',
    releaseCreated: 'Release created',
  },

  materialType: {
    ads: 'Agreement templates',
    tech: 'Technical',
    sales: 'Sales',
    partnership: 'Partnership',
    cases: 'Cases',
  },

  calendar: {
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
    custom: 'Custom',

    shortDays: ['Sun', 'Mon', 'Tu', 'Wed', 'Thu', 'Fri', 'Sat'],

    normalDays: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],

    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],

    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
};

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppHeader } from '../components/appHeader';
import useRbac from '../hooks/useRbac';
import { useAppSelector } from '../hooks/useStore';
import CitiesMainPage from '../pages/cities/main';
import CompanyProfile from '../pages/companyProfile';
import DocsMainPage from '../pages/docs/main';
import EmployeesMainPage from '../pages/employees/main';
import MaterialsMainPage from '../pages/materials';
import { MaterialAdminPage } from '../pages/materials/admin';
import PartnersMainPage from '../pages/partners/main';
import { PartnerViewPage } from '../pages/partners/view';
import ProjectsCreatePage from '../pages/projects/create';
import ProjectsMainPage from '../pages/projects/main';
import { EUserRole } from '../types/user.interface';
import NewsRoutes from './news';
import { FeedbackForm } from '../pages/feedback/form';
import Education from '../pages/education';
import config from '../app.config';

export const MainRoutes: React.FC = () => {
  const { profile } = useAppSelector((state) => state.user);
  const rbac = useRbac();
  return (
    <>
      <AppHeader />
      <Switch>
        <Route
          path='/'
          component={
            profile?.role === EUserRole.customer
              ? () => {
                window.location.href = '/documentation';
                return null;
              }
              : ProjectsMainPage
          }
          exact
        />

        <Route
          path='/materials/ads'
          render={() =>
            rbac.can('material.manager') ? (
              <MaterialAdminPage type='ads' />
            ) : (
              <MaterialsMainPage type='ads' />
            )
          }
        />
        <Route
          path='/materials/sales'
          render={() =>
            rbac.can('material.manager') ? (
              <MaterialAdminPage type='sales' />
            ) : (
              <MaterialsMainPage type='sales' />
            )
          }
        />
        <Route
          path='/materials/tech'
          render={() =>
            rbac.can('material.manager') ? (
              <MaterialAdminPage type='tech' />
            ) : (
              <MaterialsMainPage type='tech' />
            )
          }
        />
        <Route
          path='/materials/partnership'
          render={() =>
            rbac.can('material.manager') ? (
              <MaterialAdminPage type='partnership' />
            ) : (
              <MaterialsMainPage type='partnership' />
            )
          }
        />
        <Route
          path='/materials/cases'
          render={() =>
            rbac.can('material.manager') ? (
              <MaterialAdminPage type='cases' />
            ) : (
              <MaterialsMainPage type='cases' />
            )
          }
        />

        <Route path='/materials'>
          <Redirect to='/materials/sales' />
        </Route>
        <Route path='/documentation/:id?' component={DocsMainPage} />
        <Route path='/education' component={Education} />
        <Route path='/news/' component={NewsRoutes} />

        <Route
          path='/feedback'
          component={FeedbackForm}
          exact
        />

        {profile?.role !== EUserRole.customer && (
          <>
            <Route
              path='/projects/create'
              component={ProjectsCreatePage}
              exact
            />
            <Route path='/project/update/:id' component={ProjectsCreatePage} />
            <Route
              path='/partners/archive'
              render={() => <PartnersMainPage archive />}
              exact
            />
            <Route
              path='/partners/applications'
              render={() => <PartnersMainPage review />}
              exact
            />
            <Route path='/partners' component={PartnersMainPage} exact />
            <Route path='/partner/:id' component={PartnerViewPage} exact />
            <Route path='/employees' component={EmployeesMainPage} />
            <Route path='/countries' component={CitiesMainPage} />
            <Route path='/company-profile' component={CompanyProfile} />
          </>
        )}

        <Route path='*'>
          <Redirect to='404' />
        </Route>
      </Switch>
    </>
  );
};

export default MainRoutes;
